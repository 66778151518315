import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";

const AtelierPage = ({ language }) => {
  return (
    <div className="container">
      <section
        className="container "
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="row">
          <div className="col-md-8 order-2 order-md-1">
            <h1 style={{ fontWeight: "700", color: "#90A8D0" }}>
              {language === "EN" ? "L'Atelier des Mamans" : "L'Atelier des Mamans"}
            </h1>
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN" ? "Our Story" : "Notre Histoire"}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `In a small section of the SEF premises and on the 1st of January 2009, began the journey of the SEF's atelier. It was a story of love, determination, and deep appreciation for traditional tastes. `
                : `Dans une petite section des locaux de l’ONG “SEF” et à partir du 1er janvier 2009, le parcours de l'atelier de SEF a débuté. C'était une histoire d'amour, de détermination et d'appréciation profonde des goûts traditionnels.`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `Some of the mothers, who had been supported by SEF for years, were incredibly skilled at making the traditional Lebanese cookies ‘Maamoul’. Their experience and enthusiasm were so inspiring that they taught the other mothers, and together, they embarked on this inspiring journey.  `
                : `
                Certaines des mères, qui avaient été soutenues  par SEF pendant des années, étaient incroyablement habiles à fabriquer les biscuits libanais traditionnels  "Maamoul". Leur expérience et leur enthousiasme ont été si inspirants au point qu'ils ont enseigné cette pâtisserie sablée aux autres mères, et ensemble, elles  se sont lancées  dans ce parcours inspirant. 
`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `SEF recognized the potential in these delicious treats and began selling them in supermarkets across the country. The demand for ‘Maamoul’ skyrocketed as rapid word-of-mouth propagated.`
                : `SEF a reconnu le potentiel de ces délicieuses pâtisseries et a commencé à les vendre dans les supermarchés à travers le pays. La demande de "Maamoul' est montée en flèche totalement comme la puissance de la  bouche-à-oreille s’est propagée rapidement. (On veut dire par: “Bouche-à-oreille” la technique marketing WOM) . `}
              <br></br>
              <br></br>

              {language === "EN"
                ? `With the aroma of freshly baked ‘Maamoul’ filling the workspace, mothers and SEF members started to dream big and to think of ways to make this atelier grow and thrive. Therefore, they decided to expand their product line by crafting delectable delights such as macaron zaatar, refreshing lemonade, Yam Yam cookies (chocolate cookies), and anise biscuits. All of this was done in a primitive way using a normal oven and without the aid of any equipment.`
                : `Avec l'arôme du "Maamoul" fraîchement sorti du four qui remplissait le milieu de travail, les mères et les membres du SEF ont commencé à rêver plus grand pour leur “business”  et à penser à des moyens de faire grandir et de prospérer cet atelier.  Par conséquent, ils ont décidé d'élargir leur gamme de produits en fabriquant des délices tels que:  le macaron zaatar, la limonade rafraîchissante, les biscuits Yam Yam (biscuits au chocolat) et les biscuits à l'anis. À souligner que la préparation de ces délices a été faite d’une  manière primitive et en utilisant un four normal, sans l'aide d'aucun autre  équipement.`}
              <br></br>
              <br></br>
            </p>
          </div>
          <div className="col-md-4 mb-3 order-1 order-md-2 d-flex justify-content-center align-items-center">
            <Carousel
              fade="true"
              controls={false}
              indicators={false}
              interval={2000}
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-1.jpeg"
                  alt="First slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-3.jpeg"
                  alt="Second slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-4.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-5.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-6.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="/Assets/atelier-7.jpeg"
                  alt="Third slide"
                  style={{ borderRadius: "30px" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </section>
      <section
        className="container "
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div className="row" style={{ color: "#A2979A" }}>
          <div className="col-md-12 d-flex flex-column  justify-content-center">
            <p>
              {language === "EN"
                ? `The atelier's growth was swift and exciting. The mothers participated in a big number of events and exhibitions, showcasing their delicious products.`
                : `La croissance de l'atelier a été rapide et passionnante. Les mères ont participé à un grand nombre d'événements et d'expositions, présentant leurs délicieux produits.`}
            </p>
            <p>
              {language === "EN"
                ? `With each success, the atelier reinvested in itself and used the money to get better equipment that would allow them to make even more goodies. At this stage, both SEF members and the mothers decided to name the workshop 'L'Atelier des Mamans' (Moms Atelier) since these moms were the initiators and creators of the idea.`
                : `Avec chaque succès, l'atelier a réinvesti en lui-même et a utilisé l'argent pour obtenir un meilleur équipement qui leur permettrait de préparer plus de goodies. À ce stade là, les membres du SEF et les mères ont décidé ensemble de nommer cet  atelier: "L'Atelier des Mamans" (Moms Atelier) vu que les mères hébergées au SEF étaient les initiatrices et les créatrices de l'idée.`}
            </p>
            <p>
              {language === "EN"
                ? `Today, two remarkable ladies, driven by their passion and commitment, run this atelier with love and dedication. They continue to care for its growth and ensure that the legacy of SEF and the mission it stands for remain at the heart of their work.`
                : `Aujourd'hui, deux dames remarquables, animées par leur passion et leur engagement, dirigent cet atelier avec beaucoup d’amour et de dévouement. Elles  continuent à  veiller à la croissance de l’atelier et de veiller à ce que l'héritage de  SEF et la mission qu'elle représente restent au cœur de leur travail.`}
            </p>
            <p>
              {language === "EN"
                ? `Over the years, “L'Atelier des Mamans” has become well-known and its products have graced the shelves of prestigious stores such as ABC, Made by Nature, Arc en Ciel, Convivio, Oursin Bourisin, and many others.`
                : `Au fil des années, "L'Atelier des Mamans" est devenu assez reconnu,  et ses produits ont honoré les étagères des magasins prestigieux tels que l’ABC, Made by Nature, Arc en Ciel, Convivio, Oursin Bourisin et bien d'autres.`}
            </p>
            <p>
              {language === "EN"
                ? `As long as there are ladies with such passion and boundless hearts to support SEF's mission, “L'Atelier des Mamans” will continue to flourish and produce delicious treats that delight people's lives. This is a story of tradition, love, and the extraordinary journey of an atelier founded by exceptional mothers.`
                : `Tant qu'il y a des dames avec une telle passion et un cœur illimité pour soutenir la mission de SEF, “‘l’Atelier des Mamans" continuera à s'épanouir et à fabriquer des délices qui raviront la vie des gens. C'est une histoire de tradition, d'amour et d’un extraordinaire parcours  d'un atelier fondé par des mères exceptionnelles.`}
            </p>
          </div>
        </div>
      </section>

      <div className="mb-5">
        <Link
          to="/volunteer"
          style={{
            display: "flex",
            gap: "10px",
            color: "#90A8D0",
            fontWeight: "900",
          }}
        >
          {language === "EN" ? `Volunteer` : `Bénévole`}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-arrow-right-square"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default AtelierPage;
