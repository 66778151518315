import React, { useState } from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { faDonate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "../Components/Spinner";
import { callDonateApi } from "../utils/http";
import Banner from "../Components/Banner";

const DonatePage = ({ language }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amounts, setAmounts] = useState([50, 100, 150, 250, "custom"]);
  const [selectedAmount, setSelectedAmount] = useState(null);

  const handleDonate = async (amount) => {
    if (isLoading) {
      return;
    }
    setSelectedAmount(amount);
    setIsLoading(true);

    try {
      const res = await callDonateApi(amount, `Donation of $${amount}`);

      if (res?.data) {
        const resUrl = JSON.parse(res.data);
        window.location.href = resUrl.url;
      } else {
        throw res;
      }
    } catch (err) {
      //TODO: handle error
      setSelectedAmount(null);
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#90A8D0",
        color: "white",
        minHeight: "calc(100vh - 250px)",
      }}
    >
      <Banner></Banner>
      <section
        className="container p-5 "
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <div className="row justify-center">
          <div className="col-md-6 text-center justify-content-center">
            <video
              controls
              width="330"
              style={{ borderRadius: "60px" }}
              className="mx-auto"
            >
              <source src="/Assets/medco-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="col-md-6 text-center justify-content-center mt-5">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <div>
                    <FontAwesomeIcon icon={faDonate} size="2xl" />
                  </div>
                  <h2 className="mb-4 mt-4" style={{ fontWeight: "600" }}>
                    {language === "EN" ? "Donate" : "À propos de SEF"}
                  </h2>
                  <p
                    className="mb-3"
                    style={{ fontSize: 18, textAlign: "center" }}
                  >
                    {language === "EN"
                      ? `Your support means the world to us, you can donate through direct payments or by redeeming your points in the Medco App, you can check the video for a simple guide. 
                      Whether through online transactions or fuel station contributions, each donation fuels our mission and makes a difference in our community.`
                      : `Votre soutien est précieux pour nous. Vous pouvez faire un don par paiements directs ou en échangeant vos points dans l'application Medco. Consultez la vidéo pour un guide simple. 
                      Que ce soit par des transactions en ligne ou des contributions dans les stations-service, chaque don alimente notre mission et fait une différence dans notre communauté.`}
                  </p>
                </motion.div>
              )}
            </InView>
            <div className="d-flex flex-row flex-wrap justify-content-center align-items-center w-100">
              {amounts.map((amount) => {
                if (amount === "custom") {
                  return (
                    <div
                      style={{ width: 250, maxWidth: 250 }}
                      class="input-group mt-3 d-flex justify-content-center align-items-center"
                    >
                      <input
                        type="number"
                        min={1}
                        minLength={1}
                        className="form-control"
                        placeholder="Other amount"
                        disabled={isLoading}
                        onChange={(e) => {
                          setSelectedAmount(e.target.value);
                        }}
                        value={selectedAmount}
                      />
                      <button
                        className={`btn btn-outline-light mr-2 d-flex flex-row justify-items-center align-items-center ${
                          isLoading ? "disabled" : ""
                        }`}
                        type="button"
                        id="button-addon1"
                        onClick={() => {
                          if (selectedAmount < 1) {
                            return;
                          }
                          handleDonate(selectedAmount);
                        }}
                      >
                        {isLoading && selectedAmount == amount ? (
                          <Spinner />
                        ) : null}
                        Donate
                      </button>
                    </div>
                  );
                  return;
                }

                return (
                  <button
                    type="button"
                    key={`amount-${amount}`}
                    onClick={() => handleDonate(amount)}
                    className={`btn btn-outline-light mx-2 mt-3 d-flex flex-row justify-items-center align-items-center ${
                      isLoading ? "disabled" : ""
                    }`}
                  >
                    {isLoading && selectedAmount == amount ? <Spinner /> : null}
                    <div className="mx-1"></div>
                    {amount}$
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DonatePage;
