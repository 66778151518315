import React from "react";
import "./ProgramsPage.css";
import { Link } from "react-router-dom";
import Button from "../Components/Button";

const ProgramsPage = ({ language }) => {
  return (
    <>
      <div
        className="container p-4  mb-5"
        style={{
          fontFamily: "Bahnschrift",
        }}
      >
        <h1 className="mb-3" style={{ fontWeight: "700", color: "#90A8D0" }}>
          {language === "EN"
            ? "Programs and Services"
            : "Programmes et Services"}
        </h1>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `1. Welcoming Families in Need:`
                : `1. Accueillir les familles ayant besoin: `}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Accomodation for children and mothers in a familial and warm
              environment:`
                : `HÉBERGEMENT POUR LES ENFANTS ET LES MÈRES DANS UN ENVIRONNEMENT FAMILIAL ET CHALEUREUX :`}

              <br></br>
              {language === "EN"
                ? `Sharing daily life ensures an open, warm, and
              non-institutionalized "home away from home." A stable and
              high-quality form of care provides the attention and support
              essential for the psychosocial development of children and their
              mothers.`
                : `Le partage du quotidien assure un “chez soi” ouvert, chaleureux et non institutionnel. Un mode d’accueil stable et de qualité offre l'attention nécessaire et le soutien indispensable au développement psycho-social  des enfants et de leurs mères.`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `2. A Secure and Safe Environment:`
                : `2. Un environnement stable et sécurisant :`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `The presence of a protective environment and qualified personnel
              ensures the safety of beneficiaries. Additionally, security is
              further ensured through the support of a network of specialized
              institutions (lawyers, direct contacts with the Internal Security
              Forces...).`
                : `La présence d'un personnel qualifié et un environnement protecteur garantissent la sécurité des bénéficiaires. De même, la sécurité est également assurée à travers le soutien d'un réseau de structures spécialisées (les avocats, contacts directs avec les forces de sécurité intérieure...). `}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `3. A Respectful and Confidential Approach:
`
                : `3. Une approche respectueuse et confidentielle:`}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Sharing knowledge and power establishes equal relationships
              between the beneficiaries and the educational teams. The support
              provided for personal journeys is carried out with the utmost
              confidentiality.`
                : `Le partage des connaissances et du pouvoir instaure des rapports égalitaires entre les bénéficiaires et les équipes éducatives. L’accompagnent des parcours personnels s’exerce en toute confidentialité.`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `4. Individual and Collective Support:`
                : `4. Une prise en charge individuelle et collective:`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `The support provided to children, allowing them to express their
              experiences and understand their situation, helps them relieve
              guilt and take responsibility off their shoulders. The assistance
              given to mothers promotes awareness of their own journeys and
              prevents past failures from being repeated (through support
              groups, mutual help, and sharing experiences).`
                : `L’aide apportée aux enfants, pour une expression de leur vécu et une compréhension de leur situation, leur permet de se déculpabiliser et de se déresponsabiliser. L'aide apportée aux mères pour une prise de conscience de leurs propres parcours évite la répétition de l’échec (par le biais de groupes de soutien, d’entraide et de partage d'expériences).`}
              <br></br>
              <br></br>
              {language === "EN"
                ? `An environment tailored to the needs of families respects everyone's privacy and at the same time facilitates collective activities.`
                : `Un milieu de vie adapté aux besoins des familles respecte la vie privée de chacun tout en facilitant les activités collectives. `}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {" "}
              {language === "EN"
                ? `5. Accommodation for a Possible Period of 1 Year:`
                : `5. Un accueil pour une durée possible d'un an:`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Support and guidance are provided at the very beginning of the
              journey to assist the mother in overcoming victimhood (once the
              danger has been eliminated), making decisions, developing
              self-confidence, and regaining control of her life.`
                : `Une première réponse aux besoins de soutien et d’accompagnement est apportée pour aider la mère à surmonter la victimisation (une fois que le danger est éliminé), à prendre des décisions, à développer la confiance en elle-même et à amorcer la reprise d’un pouvoir sur sa vie. `}
              <br></br>
              <br></br>
              {language === "EN"
                ? `A second phase of consolidation takes
              place, focusing on strengthening the mother-child bond and
              building a comprehensive plan for parenting. This phase also
              includes personal growth and the pursuit of autonomy on all
              professional, economic, social, and emotional levels.`
                : `Une deuxième étape de consolidation aura lieu, en se basant sur le renforcement du lien mère-enfant et l'élaboration d'un plan complet pour la parentalité. Cette étape vise à atteindre également la croissance personnelle et la poursuite de l'autonomie sur tous les niveaux: professionnels, économiques, sociaux et émotionnels.`}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#90A8D0", height: "auto" }}
        className="d-flex justify-content-center align-items-center"
      >
        <section className="container" style={{ fontFamily: "Roboto" }}>
          <div className="row justify-content-center p-3">
            <div className="col-md-6 col-lg-4 d-flex justify-content-center mb-3">
              <div className="card text-center card-custom">
                <div className="card-body">
                  <h3 className="card-title" style={{ color: "#90A8D0" }}>
                    Volunteer
                  </h3>
                  <p className="card-text" style={{ color: "#A2979A" }}>
                    Become a part of our mission by volunteering your time and
                    skills.
                  </p>
                  <Link to="/volunteer">
                    <Button button="Volunteer Now!" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 d-flex justify-content-center mb-3">
              <div className="card text-center card-custom">
                <div className="card-body">
                  <h3 className="card-title" style={{ color: "#90A8D0" }}>
                    Success Stories
                  </h3>
                  <p className="card-text" style={{ color: "#A2979A" }}>
                    Read more about some of the success stories
                  </p>
                  <Link to="/success">
                    <Button button="Learn more" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="container p-4 mt-5 mb-5"
        style={{
          fontFamily: "Roboto",
        }}
      >
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `6. Co-developing a project with the mother for the child:`
                : `6. Co-produire avec la mère un projet pour l'enfant:`}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Children without obvious problems (prevention).`
                : `Enfants sans problèmes apparents (prévention)`}
              <br></br>
              {language === "EN"
                ? `Children with lingering effects (psycho-social and educational
              treatment).`
                : `Enfants avec séquelles (traitement psycho-social  et éducatif)
`}
              <br></br>
              {language === "EN"
                ? `Children with legal cases (support through referral to legal
              actions).`
                : `Enfants avec dossiers judiciaires (accompagnant des mesures judiciaires)
`}
              <br></br>
              {language === "EN"
                ? `This diagnosis highlights the needs, strengths, and assets of the
              children, as well as the mother's ability to support them. It
              leads to the exploration and proposal of solutions that meet their
              needs.`
                : `Ce diagnostic met en évidence les besoins, les forces et les atouts des enfants, ainsi que la capacité de la mère à les soutenir. Il débouche sur la recherche et la proposition de solutions répondantes à leurs besoins.`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `7. Developing a personalized project with the mother for the child:`
                : `7. Élaborer avec la mère un projet personnalisé pour l'enfant:`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `The project for the child outlines goals and action plan.`
                : `Le projet pour l'enfant détermine les objectifs et le plan d'action.`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `8. Supporting the mother in implementing this project:`
                : `8. Accompagner la mère dans la mise en œuvre de ce projet:`}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `The mother is responsible for her child's project and receives
              support that decreases gradually as progress is made. The
              assistance provided by the teams focuses on evaluating and
              revising achieved and future goals over time.`
                : `La mère est la responsable du projet de son enfant, elle bénéficie d’un appui qui va décroissant. L'aide fournie par les équipes porte, dans la durée, sur l'évaluation et la révision des objectifs atteints et à atteindre.`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `9. Supporting mothers as individuals:`
                : `9. Soutenir les mères en tant que personnes:`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Ensuring their physical and psychological well-being and personal
              development. Identifying and addressing their mental health issues
              is crucial for recognizing their value as women. The mother's
              engagement in her own action plan enhances her sense of life
              self-control and lifts her self-esteem.`
                : `Veiller à la santé physique et psychologique, et au développement personnel. La mesure de leur détresse psychologique puis la manière d’y remédier contribue à la reconnaissance de leur valeur en tant que femmes. L'engagement de la mère dans son propre plan d'action développe son sentiment de contrôle sur sa vie et son estime de soi.
`}
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <h2
              className="mb-3"
              style={{ fontWeight: "600", color: "#90A8D0" }}
            >
              {language === "EN"
                ? `10. Maintaining and rebuilding family bonds:`
                : `10. Maintenir et reconstruire les liens familiaux:`}{" "}
            </h2>
            <p className="mb-3" style={{ color: "#A2979A" }}>
              {language === "EN"
                ? `Family mediation work aims to break the impasse and conflict and
              restore relationships when deemed necessary for the mother and/or
              her children.`
                : `Le travail de médiation familiale vise à sortir de l'impasse et du conflit et à rétablir les relations lorsque cela est jugé nécessaire pour la mère et/ou ses enfants.`}
            </p>
          </div>
        </div>
        <div className="mb-5">
          <Link
            to="/success"
            style={{
              display: "flex",
              color: "#90A8D0",
              fontWeight: "900",
              gap: "10px",
            }}
          >
            {language === "EN" ? `Success Stories` : `Histoires de Réussite`}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-arrow-right-square"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
              />
            </svg>{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ProgramsPage;
