import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <div className="moving-text">
        <span>Exciting News Alert! 🌟 We're thrilled to announce that SEF has officially become a new member on the Medco App! 🎉 Your support means the world to us, and now you can redeem your points in the app by donating to SEF! 💙 Kindly share it with everyone you know 🙏Let's make a difference together!</span>
        {/* <span>Exciting News Alert! 🌟 We're thrilled to announce that SEF has officially become a new member on the Medco App! 🎉 Your support means the world to us, and now you can redeem your points in the app by donating to SEF! 💙 Kindly share it with everyone you know 🙏Let's make a difference together!</span> */}
      </div>
    </div>
  );
}

export default Banner;
