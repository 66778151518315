import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaInstagram, FaFacebookF, FaEnvelope, FaPhone } from "react-icons/fa";

import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={12} className="text-center">
            <address>
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/sefliban.ngo/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <FaInstagram size={30} className="social-icon mx-2" />
                </a>
                <a
                  href="https://www.facebook.com/yourFacebookHandle"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  <FaFacebookF size={30} className="social-icon mx-2" />
                </a>
                {/* to be updated later */}
                <a
                  href="mailto:info@example.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Email"
                >
                  <FaEnvelope size={30} className="social-icon mx-2" />
                </a>
                <a
                  href="tel:+9613302534"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Phone"
                >
                  <FaPhone size={30} className="social-icon mx-2" />
                </a>
              </div>
            </address>
          </Col>
        </Row>
        <hr />
        <div className="text-center">
          <p>
            &copy; {currentYear} Service de l'Enfant au foyer. All rights
            reserved.
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
